import { css } from "@emotion/core"
import "normalize.css"
import { specialFont } from "../../utils/style/styleUtils"

export const fontFamily = `"Open Sans", sans-serif`

export const mainLayoutCss = css`
  * {
    margin: 0;
    padding: 0;
  }

  select {
    max-width: 100%;
  }

  img {
    display: block;
    max-width: 100%;
  }

  body {
    font-family: ${fontFamily};
    line-height: 1.6;
    color: #555;
    font-size: 16px;
  }

  h1,
  h2,
  h3 {
    ${specialFont};
    font-weight: normal;
    line-height: 1.2;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
  }

  h1 {
    font-size: 3rem;
    color: #e7272d;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #e7272d;
  }
  h3 {
    font-size: 2.2rem;
    font-weight: 500;
  }

  a {
    color: #e7272d;
    text-decoration: inherit;
  }
`
