import { combineReducers } from "@reduxjs/toolkit"
import { formSlice } from "./form/formSlice"
import { cartSlice } from "./cart/cartSlice"
import { settingsSlice } from "./settings/settingsSlice"

export const rootReducer = combineReducers({
  cart: cartSlice.reducer,
  form: formSlice.reducer,
  settings: settingsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>
