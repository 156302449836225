import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { WithChildren } from "./utils/types/reactTypes"
import { fontFamily } from "./components/layout/mainLayoutCss"

const theme = createMuiTheme({
  typography: {
    fontFamily,
  },
  palette: {
    primary: {
      main: "#e7272d",
    },
    text: {
      primary: "#555",
    },
  },
})

function AppWithTheme(props: AppWithThemeProps) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}

interface AppWithThemeProps extends WithChildren {}

export default AppWithTheme
