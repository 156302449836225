import { css } from "@emotion/core"

export const primaryColor = "#e10713"

export const cssResetButton = css`
  border: none;
  background-color: unset;
`

export function flexMargin(margin: number) {
  return `
    margin: -${margin}px;
    > div {
      margin: ${margin}px;
    }
  `
}

export const specialFont = css`
  font-family: "Cookie", cursive;
`

export const clickableCss = css`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`
