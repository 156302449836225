import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState } from "../rootReducer"
import { useDemoValues } from "../../config/config"

interface State {
  [key: number]: number
}

const initialState: State = !useDemoValues
  ? {}
  : {
      23: 2,
      101: 1,
    }

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setProductQuantity: (
      state,
      { payload: { productId, count } }: ProductAction<{ count: number }>
    ) => {
      if (!count) {
        delete state[productId]
      } else {
        state[productId] = count
      }
    },
    addProductQuantity: (
      state,
      { payload: { productId, count } }: ProductAction<{ count: number }>
    ) => {
      const nextCount = (state[productId] || 0) + count
      if (nextCount > 0) {
        state[productId] = nextCount
      } else {
        delete state[productId]
      }
    },
  },
})

type ProductAction<A> = PayloadAction<A & { productId: number }>

export const cartReducer = cartSlice.reducer
export const cartActions = cartSlice.actions

export function useCart() {
  return useSelector((state: RootState) => state.cart)
}

export function useCartItem(id: number) {
  return useSelector((state: RootState) => state.cart[id]) || 0
}
