import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../rootReducer"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useMemo } from "react"
import { useDemoValues } from "../../config/config"
import { CompareFn } from "../../utils/types/reactTypes"
import { addDays, setHours } from "../../utils/dateUtils"
import { Branch } from "../../types/Branch"

interface SerializedState {
  name: string
  phone: string
  date?: string | Date | null
  branch?: Branch
}

export interface FormState extends SerializedState {
  date: Date | null
}

const initialState: SerializedState = {
  name: useDemoValues ? "Max Mustermann" : "",
  phone: useDemoValues ? "0123456" : "",
  date: useDemoValues ? setHours(addDays(new Date(), 2), 10).toJSON() : null,
}

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setBranch: (state, action: PayloadAction<Branch | undefined>) => {
      state.branch = action.payload
    },
    setDate: {
      reducer: (state, action: PayloadAction<string | undefined>) => {
        state.date = action.payload
      },
      prepare: (args?: Date) => {
        return { payload: args?.toJSON() }
      },
    },
  },
})

export const formActions = formSlice.actions

export function useSelectedBranch() {
  const dispatch = useDispatch()
  const branch = useForm()?.branch
  return useMemo(
    () => ({
      branch,
      setBranch: (branch?: Branch) => {
        dispatch(formActions.setBranch(branch))
      },
    }),
    [branch]
  )
}

const selectSlice = (state: RootState) => state.form
export const selectForm = createSelector(selectSlice, (form) => {
  const serialized: FormState = {
    ...form,
    date: form.date ? new Date(form.date) : null,
  }
  return serialized
})

export function useForm(
  compare: CompareFn<FormState> = shallowEqual
): FormState {
  return useSelector(selectForm, compare)
}

export function useSelectedDate() {
  return useSelector(selectForm, (left, right) => left.date === right.date).date
}
