import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../rootReducer"
import { CompareFn } from "../../utils/types/reactTypes"
import { useSelector, shallowEqual } from "react-redux"

interface State {
  email: string
  showSettings: boolean
}

const initialState: State = {
  email: "",
  ...fromCache(),
  showSettings: false,
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<State>>) => {
      Object.assign(state, action.payload)
      localStorage.setItem("settings", JSON.stringify(state))
    },
  },
})

function fromCache(): Partial<State> | undefined {
  if (typeof localStorage === "undefined") return undefined
  const settings = localStorage.getItem("settings")
  return settings && JSON.parse(settings)
}

export const settingsSelector = (state: RootState) => state.settings
export function useSettings(isEqual: CompareFn<State> = shallowEqual) {
  return useSelector(settingsSelector, isEqual)
}

export const settingsActions = settingsSlice.actions
