import React from "react"
import { Provider } from "react-redux"
import { createStore } from "./store/store"
import AppWithTheme from "./AppWithTheme"

export function wrapApp({ element }: { element: any }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return (
    <Provider store={store}>
      <AppWithTheme>{element}</AppWithTheme>
    </Provider>
  )
}
