export function addDays(date: Date, days: number) {
  date.setDate(date.getDate() + days)
  return date
}

export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export function setHours(date: Date, hours: number) {
  date.setHours(hours)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

const weekdayNames = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
]

export function findNextWeekday(date: Date, weekday: Weekday) {
  const target = new Date(date.getTime())
  target.setDate(target.getDate() + 1) // to prevent selecting the same date
  target.setHours(6, 0, 0, 0)
  while (target.getDay() !== weekday) {
    target.setDate(target.getDate() + 1)
  }
  return target
}

export function isDateBetween(from: Date, to: Date, target = new Date()) {
  return target.getTime() >= from.getTime() && target.getTime() <= to.getTime()
}

export function isSameDay(a: Date, b: Date) {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  )
}
